import React, { useEffect, useState } from "react";
import { Input, Row, Col, Form, Select, Divider, Button } from "antd";
import { Box, Text, Stack, Heading } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import { BsBackpack2Fill, BsFillCartCheckFill } from "react-icons/bs";
import Card from "components/card/Card.js";
import { controller } from "./controller";
import { useLocation, useHistory } from "react-router-dom";
const Success = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      // Check for query parameters in the URL
      const searchParams = new URLSearchParams(window.location.search);
      const paymentIntent = searchParams.get("payment_intent");
      const clientSecret = searchParams.get("payment_intent_client_secret");
      const redirectStatus = searchParams.get("redirect_status");

      if (paymentIntent && clientSecret && redirectStatus) {
        // Build the desired URL format
        const formattedUrl = `/#/admin/success/?payment_intent=${paymentIntent}&payment_intent_client_secret=${clientSecret}&redirect_status=${redirectStatus}`;

        // Replace current URL with the formatted one
        window.history.replaceState(null, "", formattedUrl);
      }
    } catch (error) {
      console.error("Error processing URL parameters:", error);
      setError("An error occurred while processing the URL.");
    }
  }, []);

  useEffect(() => {
    try {
      const storedTransactionData = localStorage.getItem("transactionData");

      if (storedTransactionData) {
        setTransactionData(JSON.parse(storedTransactionData));
        localStorage.removeItem("paymentIntent");
      } else {
        setError("No payment information found in localStorage.");
      }
    } catch (error) {
      console.error("Error reading transaction data:", error);
      setError("An error occurred while reading transaction data.");
    }
  }, []);

  useEffect(() => {
    if (!transactionData) return;

    const payload = {
      amount: transactionData?.amount || 0,
      correlation_id: transactionData?.correlation_id || "",
      source_client_id: transactionData?.source_client?.id || null,
      status: transactionData?.status || "",
      currency: transactionData?.currency || "",
      destination_client_id: transactionData?.destination_client?.id || null,
    };

    const createTransaction = async () => {
      try {
        const transactionInformation = await controller.createTransaction(
          payload
        );
        console.log("Transaction Information:", transactionInformation);
      } catch (error) {
        console.error("Error creating transaction:", error);
        setError("An error occurred while creating the transaction.");
      }
    };

    createTransaction();
  }, [transactionData]);

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!transactionData) {
    return <p>No payment information found. Please try again.</p>;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Card px="10%" mb="20px">
          <React.Fragment>
            <Heading
              size="md"
              style={{
                color: "#1bad1b",
                marginBottom: "1px",
                display: "flex",
              }}
            >
              <BsFillCartCheckFill
                color="green"
                style={{ marginRight: "8px" }}
              />
              Payment was successful..
            </Heading>
            <Row
              type="flex"
              style={{ marginTop: "3%", lineHeight: "25px" }}
              justify={"space-between"}
            >
              <Col span={12}>
                <Text>Name:</Text>
                <Text
                  fontWeight="bold"
                  style={{ marginLeft: "5px", marginBottom: "15px" }}
                >
                  {transactionData.source_client.name
                    ? transactionData.source_client.name
                    : "-"}
                </Text>
              </Col>
              <Col span={12}>
                <Text>Email:</Text>
                <Text
                  fontWeight="bold"
                  style={{ marginLeft: "5px", marginBottom: "15px" }}
                >
                  {transactionData.source_client.email
                    ? transactionData.source_client.email
                    : "-"}
                </Text>
              </Col>

              <Col span={12}>
                <Text>Office:</Text>
                <Text
                  fontWeight="bold"
                  style={{ marginLeft: "5px", marginBottom: "15px" }}
                >
                  {transactionData.destination_client.name
                    ? transactionData.destination_client.name
                    : "-"}
                </Text>
              </Col>

              <Col span={12}>
                <Text>Cost:</Text>
                <Text
                  fontWeight="bold"
                  style={{ marginLeft: "5px", marginBottom: "15px" }}
                >
                  {transactionData.amount
                    ? "$" + "  " + transactionData.amount
                    : "-"}
                  {transactionData.currency.toUpperCase()}
                </Text>
              </Col>
              <Row
                justify={"center"}
                style={{ width: "100%", marginTop: "3%" }}
              >
                <Button type="primary" href="/" style={{ minWidth: "100%" }}>
                  {"<"} Back to Dashboard
                </Button>
              </Row>
            </Row>
          </React.Fragment>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default Success;
