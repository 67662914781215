import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { controller } from "./controller";

// const stripePromise = loadStripe(
//   "pk_test_51QchiXDXf00oGc2Wx1ansFTu5EOYTdGkm8CTLkz6I9mjTJ3SYx1B3XUDczlhgLqu0HDjVhXHIbAbj5RT9daACYEN00YgGZz3ec",
//   {
//     stripeAccount: "acct_1QdSgyDIIwIZK9AB",
//   }
// );


function App({ amount, customer }) {
  const [clientSecret, setClientSecret] = useState("");
  const [transactionData, setTransactionData] = useState("");

    const [account_id, setAccount_id] = useState("");
    const [stripeKey, setStripeKey] = useState("");
  
    const getStripeCredentials = async () => {
      const response = await controller.getStripeCredentials();
      console.log(response.public_key);
      setStripeKey(response.publishable_key);
      setAccount_id(response.account_id);
  };
  

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        const response = await controller.createClientSecret(amount, customer);
        setClientSecret(response.data.response.client_secret);
        setTransactionData(response.data);
      } catch (error) {
        console.error("Error fetching clientSecret:", error);
      }
    };
    getClientSecret();
    getStripeCredentials();
  }, [amount, customer]);

  localStorage.setItem("transactionData", JSON.stringify(transactionData))

  console.log("My Client Secret:", clientSecret);

  const stripePromise1 = loadStripe(stripeKey, {
      stripeAccount: account_id,
    });

  const appearance = { theme: "stripe" };
  const options = { clientSecret, appearance };

  return (
    clientSecret && (
      <Elements stripe={stripePromise1} options={options}>
        <PaymentForm clientSecret={clientSecret} transactionData={transactionData} />
      </Elements>
    )
  );
}

export default App;
