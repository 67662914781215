import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button } from "antd";
import { controller } from "./controller";

const PaymentForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useHistory();

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (clientSecret && stripe) {
        try {
          const { paymentIntent } = await stripe.retrievePaymentIntent(
            clientSecret
          );
          console.log("Retrieved PaymentIntent:", paymentIntent);
          setPaymentIntent(paymentIntent);
        } catch (err) {
          console.error("Error retrieving payment intent:", err);
          setError(`Error retrieving payment intent: ${err.message}`);
        }
      }
    };

    fetchPaymentIntent();
  }, [clientSecret, stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js has not yet loaded
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/#/admin/success/?`, 
      },
    });

    if (error) {
      setError(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded" && window.location.href.includes('admin/success')) {
      setMessage("Payment successful!");
      
      //navigate("/#/admin/success");
    } else {
      setError(`Payment status: ${paymentIntent.status}`);
    }

    setIsProcessing(false);
  };

  localStorage.setItem("paymentIntent", JSON.stringify(paymentIntent));

  return (
    <div>
      <form
        style={{ display: "block", marginTop: "20px", padding: "30px" }}
        id="payment-form"
      >
        <PaymentElement id="payment-element" />
        <Button
          onClick={handleSubmit}
          style={{ minWidth: "100%", marginTop: "10px" }}
          type="primary"
          disabled={!stripe || !elements || isProcessing}
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </Button>
      </form>
      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
      {message && (
        <div style={{ color: "green", marginTop: "10px" }}>{message}</div>
      )}
      {/* {paymentIntent && (
        <div style={{ marginTop: "20px" }}>
          <p>Payment ID: {paymentIntent.id}</p>
          <p>Status: {paymentIntent.status}</p>
          <p>
            Amount: {paymentIntent.amount / 100}
            {paymentIntent.currency.toUpperCase()}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default PaymentForm;
