import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { Divider, Popconfirm } from "antd";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import React, { useMemo, useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Controller } from "Controller/Controller";
import { Alert } from "../../../../components/Alert/Alert";

function TransactionTable(props) {
  console.log(props);

  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  console.log("data");
  console.log(data);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("navy.700", "white");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  useEffect(() => {
    var b = window.location.href;
    localStorage.setItem("viewMember", b.split("/")[b.split("/").length - 1]);
  }, []);

  if (tableData.length === 0) {
    return (
      <Flex
        direction="column"
        w="100%"
        align="center"
        justify="center"
        py="20px"
      >
        <Text color={textColor} fontSize="lg" fontWeight="600">
          No transactions available.
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify="space-between"
          w="100%"
          px="22px"
          pb="20px"
          mb="10px"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        >
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Payments
          </Text>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {columnsData.map((column, index) => (
                  <Th pe="10px" key={index} borderColor="transparent">
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.Header}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {props.tableData.map((row) => (
              <Tr key={row.id}>
                <Td>{row.amount ? row.amount : "-"}</Td>
                <Td>
                  {row.created
                    ? new Date(row.created)
                        .toISOString()
                        .replace(/T/, " ")
                        .replace(/\.\d+Z$/, "")
                    : "-"}
                </Td>
                <Td>{row.currency ? row.currency : "-"}</Td>
                <Td>{row.description ? row.description : "-"}</Td>
                <Td>{row.status ? row.status : "-"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default TransactionTable;
